import React from 'react';
import Footer from '../sections/Footer';


export const Privacy = () => (
  <div className="privacy">
    <main className='main-area'>
      <h2>Privacy Policy</h2>
      <article className='main-content'>

        <p>This Privacy Policy (the “Privacy Policy”) is a legal and binding agreement between You, an individual user (“You” or “Your”) and Spotlightly Inc. (“Spotlightly”), in relation to all games and applications made available by Spotlightly (jointly and interchangeably referred to as the “Services”), and any information, text, graphics, video, sound, pictures, and any other materials appearing, sent, uploaded, communicated, transmitted or otherwise made available via the above mentioned Services (jointly referred to as the “Content”).</p>
        <h4>Introduction</h4>
        <p>This document explains how Spotlightly collects, processes, stores and/or shares any personal data and/or information from You (jointly referred to as “Information”). By accessing and/or using the Services You consent to the collection, transfer, manipulation, storage, disclosure and other uses of Your information as described in this Privacy Policy and the Terms of Use. If You have any concerns about providing data, or having it used in any manner permitted in this Privacy Policy then You should not use the Services. </p>
        <h4>The Information collected</h4>
        <p>Spotlightly may collect and process the following data about You: information that You provide when You fill in forms when accessing and/or using the Services, or when You create an account within the Services; details of Your use of the Services and the resources that You access; the correspondence between You and Spotlightly and any interactions, or with other users of the Service, the channels and/or any social media related; from third parties who hold data about You and who agree to share this data with us; and information collected via cookies and other similar technologies and/or other mechanisms, as explained further below.</p>

        <p>				I. Cookies: Spotlightly uses cookies and other similar technologies in the Services. These technologies operate either by placing a small file which stores some information on Your computer or mobile device; and/or by accessing information on Your device. Spotlightly uses cookies and similar technologies to recognize You (and Your device); to allow the Services to interact with a third party social network or platform where You have chosen to allow such interaction; to allow payment processes when You submit payment instructions; to enable Spotlightly and third parties to provide You with more customized services; and to collect data such as Your device’s model, operating system and screen size, other applications installed on Your device, and information about how You use the Services. By accessing and/or using the Services You consent to the use of cookies and similar technologies in accordance with this Privacy Policy. You can disable cookies through Your web or mobile device browser settings but some features of the Services may not function properly. Alternatively, if You do not wish such data collection as described in this section, You should stop using the Services.</p>

        <p>II. Ads: Advertisers on mobile devices sometimes use advertising identifiers to enable and optimize their advertising. These identifiers are non-permanent, non-personal, device identifiers. Spotlightly may use Your device’s advertising identifier and other information associated with it, to deliver ads that relate to Your interests and to improve and measure the effectiveness of ad campaigns.</p>

        <p>III. Location Information: You may choose to publish Your location in Your Spotlightly profile. You may also provide Your location when You enable Your device to send such location information. Spotlightly may use and store information about Your location to provide special features and/or to improve and customize the Services.</p>

        <p>IV. Links: Spotlightly may keep track of how You interact with links across the Services, including email notifications, third-party services, and client applications, by redirecting clicks or through other means.</p>

        <p>V. Log Data: Servers automatically record information created by Your use of the Services. This data may include information such as Your IP address, browser type, operating system, the referring web page, pages visited, location, Your mobile carrier, device and application IDs, search terms, and cookie information. Log data is received when You interact with the Services. This data is used to provide the Services and to measure, customize, and improve them.</p>

        <p>VI. Payment information: If You make a purchase in the Services, Spotlightly may collect the billing and financial information necessary to process the charges; and/or do so on behalf of the relevant payment service providers. Purchases of third party services are subject to the policies applicable to such provider.</p>

        <p>VII. Third-party services: Spotlightly uses a variety of third-party services to help its provision of the Services, such as hosting and other services. These third-party service providers may collect information sent by Your browser as part of a web page request, such as cookies or Your IP address. Also, third-party ad partners may share information to measure ad quality and tailor ads, for example to display ads about things You may have already shown interest in.</p>

        <p>VIII. Customer Support Correspondence: When You ask for assistance from Spotlightly customer support, the contact information You provide will be collected, as well as information about Your game play or activity on the Service, Your user ID number, and the correspondence and any information contained within. If available through the Services, You may provide Spotlightly with profile information to make public, such as a short biography, location, website, cell phone, a picture, information to customize Your account, etc. Such contact information may be used to send You information about the Services or related information. You may use Your account settings to unsubscribe from notifications from Spotlightly and/or from other You and find You You know. You may also unsubscribe by following the instructions contained within the notification or the instructions on the Service. Also Spotlightly may use Your contact information to help others find Your account, including through third-party services and client applications. Providing any additional information described in this section is entirely optional.</p>
        <h4>Use of Information</h4>
        <p>When You create or configure an account in the Services, You provide some personal information, such as Your name, username, password, and email address. Some of this information, for example, Your name and/or username, may be listed publicly on the Services, including on Your profile page and in search results. You agree that Spotlightly and/or third parties on its behalf and/or its partners may use the Information for the purposes of contacting You as part of customer support; to send You updates or information about the Services; managing Your account and relationship with the Service and improving Your experience when You use it, improving the Services, research, surveying, and engaging with You, for example by sending You communications for these purposes; marketing and promotion of the Services or products; to personalize and optimize the Services, promotional content and/or advertising; to create reports, analysis or similar services for the purposes of research or business intelligence.</p>
        <h4>Information sharing and disclosure</h4>
        <p>Spotlightly will only share Your data with third parties with Your consent (including as set out in this Privacy Policy), or as reasonably necessary in order to provide the Services, for example, by providing Information to suppliers that Spotlightly may use to fulfill the Services; where it is necessary to carry out Your instructions, for example, to process a payment instruction Your Information has to be provided to the payment processors; where Your data is on an anonymous and aggregated basis, meaning You could not be personally identified from it; when You submit information as part of a competition or otherwise interact with any channel or social media, the information You submitted may be published; as it’s reasonably believed is permitted by law or regulation; in order to comply with any legal obligation, or in order to enforce or apply the Terms, this Privacy Policy and/or any other agreement with You; or to protect the rights and/or property of Spotlightly or third-party´s rights and/or property. Spotlightly may share or disclose Your non-private information, such as public user profile information, public messages, e-mail, etc., or share or disclose Your information in an anonymous or aggregated basis in a manner that does not allow Your personal identification.</p>
        <h4>Interaction with social networks and/or platforms</h4>
        <p>You may allow the Services to interact with any third party social network and/or platforms, such as Facebook, which will provide data about You to Spotlightly. Since any other applications or websites different from the Services are owned by a third party, You must ensure that You read their terms of service and the applicable privacy policies. You understand that when You allow the Services to interact with any third party social network and/or platform, Spotlightly may share data about You with Your contacts and other You of the Services and vice versa. This Data may include Your name, profile picture, activity status, and information related to Your use of the Services.</p>
        <h4>Term</h4>
        <p>Spotlightly may retain Information for as long as is necessary to fulfill the purposes for which it was collected or as needed to provide the Services, even after You have discontinued or deleted any account, or after the end of the provision of the Services, if retention of such Information is reasonably necessary to comply with legal obligations, meet regulatory requirements, resolve disputes between You, prevent fraud, or any other use.</p>

        <h4>Protection of Information</h4>
        <p>Spotlightly maintains commercially reasonable technical and physical safeguards to protect Information against accidental or unlawful destruction or loss, alteration, unauthorized disclosures or access, and any other unlawful forms of processing of the data in its possession. However, Spotlightly does not guarantee that Information will not be accessed, disclosed, altered or destroyed by breach of any of the above mentioned safeguards. Information may be transferred to and/or stored at worldwide destinations. Spotlightly takes all steps reasonably necessary to ensure that Information is treated securely and in accordance with this Privacy Policy. In the event that Spotlightly is involved in a bankruptcy, merger, acquisition, reorganization or sale of assets, Your information may be sold or transferred as part of that transaction. The undertakings in this Privacy Policy shall apply to the Information as transferred to the new entity. In the Services You may find links to third party websites. You understand that when You click on these links any data which You provide afterwards is subject to that third party's privacy policy and not to Spotlightly’s. Consequently, Spotlightly takes no responsibility for the content, safety or security of any third party website. The Services are not directed to persons under legal age. Spotlightly does not knowingly collect any Information from children under legal age. If You become aware that a child under legal age has provided personal information, steps will be taken to remove such information and terminate such account. If You become aware that any child has provided personal information without the essential legal guardian consent, please contact: mike@spotlightly.com. </p>

        <p>Notwithstanding anything to the contrary in this Policy, Spotlightly may preserve or disclose Your information to the extent reasonably necessary to comply with a law, regulation or legal request; to protect the safety of any person; to address fraud, security or technical issues; or to protect Spotlightly's rights or property. </p>


        <p>Nothing in this Privacy Policy is intended to limit any legal defenses or objections that You may have to a third party’s actions, including a government’s request to disclose Your information. Spotlightly wants to make sure that Your Information is accurate and up to date. You may ask to modify, correct or remove information with the tools and account settings of the Service, or otherwise by contacting Spotlightly at mike@spotlightly.com. </p>

        <p>Information that You have shared via the Services on any social network or third party platform may not be amended or deleted where it is in the control of a third party network and/or platform. </p>

        <p>If You would not like Your Information to be used for marketing purposes, You may change Your marketing preferences, to unsubscribe, or if You have any questions or comments about this Privacy Policy, please contact Spotlightly: mike@spotlightly.com. </p>

        <p>Spotlightly may assign this agreement without notice as part of a sale of all or substantially all of its assets. You are not permitted to assign this agreement. </p>

        <p>If any court or other competent authority finds any of this Privacy Policy to be invalid or unenforceable, the other terms of this Privacy Policy will not be affected. This Privacy Policy is governed by and interpreted in accordance with the laws of Canada. </p>

        <p>The most current version of the policy will govern the use of the Information and will be at https://www.accountabull.com/privacy. </p>

        <p>Spotlightly reserves the right to amend this agreement by providing you with 30 days notice (“Amended Terms”). You are not permitted to amend this agreement. Spotlightly may notify You of Amended Terms via email (to the email address associated with Your account). If You object to any of the changes You should immediately stop using the Services and closeyou’re your account. By continuing to access or use the Services after those changes become effective, You agree to be bound by the revised Privacy Policy.</p>

        <h4>Child Safety Standards Policy</h4>

<p>At Spotlightly Inc., we are committed to ensuring the safety and well-being of all users, particularly children, on our platform. We take seriously our responsibility to prevent and address child sexual abuse and exploitation (CSAE) and child sexual abuse material (CSAM).</p>

<h4>Published Standards</h4>
<p>We have established clear standards against CSAE, which are outlined below:</p>

<p>Prevention of CSAE: We prohibit any content or behavior that exploits, abuses, or endangers children. This includes grooming, sextortion, trafficking, or any form of sexual exploitation.</p>

<p>Reporting Mechanism: Users can report any concerns or violations through our in-app feedback mechanism, which is accessible without leaving the app.</p>

<p>CSAM Response: We take immediate action to remove CSAM when identified and comply with all relevant laws and regulations.</p>

<h4>Compliance with Laws</h4>
<p>We ensure full compliance with all applicable child safety laws and regulations, including but not limited to the Children's Online Privacy Protection Act (COPPA) and the General Data Protection Regulation (GDPR) where applicable.</p>

        <p>Last updated on Mar.22, 2025.</p>
      </article>
    </main>

    <Footer />
  </div>

)